.user-dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  min-width: 30px;
  height: 32px;
  border-radius: 4px;
  background: #fff;
  // color: #fff;
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }
}
.user-dropdown-phone {
  margin: 0 6px;
}
.user-dropdown-left {
  display: flex;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
  }
}