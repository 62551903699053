.page-login {
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 800px;
  background-size:cover;

  background-position-y: -10%;
  .page-header {
    font-size: 24px;
    color: #fff;
    padding: 0px;
    padding-left: 20px;
    background-color: #73797f;
  }
  .page-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 90%;
    padding-right: 80px;
    .ant-card-body {
      padding-bottom: 0;
    }
  }
  .page-form-card {
    width: 470px;

    &-footer {
      margin-top: 20px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #5b5f65;
      background: #f8f9fa;
      background: #f8f9fa;
      margin: 24px -24px 0;
      border-radius: 0 0 8px 8px;
      div {
        cursor: pointer;
      }
    }
  }
}
