@import '@/styles/responsive.scss';

body {
  overflow-x: hidden;
  width: 100%;
  max-width: 100%;
  background:  #f5f5f5;
}
.midway-page-layout {
  height: 100vh;
  .top-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    z-index: 100;
    top: 0;
    width: 100%;
    box-sizing: border-box;

    .top-menu-container {
      flex: 1;
      margin: 0 20px 1px 0;
      .menu-icon{
        margin-right: 5px;
      }
      & > ul {
        display: flex;
        padding-left: 0;
        margin: 0;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        list-style: none;
        & > li {
          position: relative;
          &.divider {
            color: #ccc;
            padding: 0 5px;
          }
          &:hover > ul {
            visibility: visible;
            opacity: 1;
          }
          & > a {
            display: block;
            white-space: nowrap;
            padding: 0 15px;
            line-height: 64px;
            height: 64px;
            // color: #e0e0e0;
            // &:hover{
            //   color: #fff;
            // }
          }
          // & > a.active {
          //   color: #fff;
          //   &:after {
          //     content: '';
          //     position: absolute;
          //     bottom: 0;
          //     left: 0;
          //     display: block;
          //     height: 4px;
          //     width: 100%;
          //   }
          // }
          & > ul {
            padding-left: 0;
            margin: 0;
            list-style: none;
            position: absolute;
            z-index: 9;
            top: 100%;
            opacity: 0;
            transition: all 0.3s ease;
            background-color: #37f;
            min-width: 160px;
            left: 0;
            visibility: hidden;

            & > li {
              height: 50px;
              position: relative;
              &.divider {
                margin: 5px 15px;
                height: 1px;
                background: #ccc ;
                content:none;
                font-size: 0;
              }
              &.divider:hover {
                background-color: #ccc;
              }
              &:hover {
                background-color: rgba(26, 29, 36, 0.5);
              }
              &:hover > ul {
                visibility: visible;
                opacity: 1;
              }
              & > a {
                display: block;
                height: 100%;
                line-height: 50px;
                padding: 0 15px;
                color: #e0e0e0;
                &.active{
                  color: #ffffff;
                }
              }
              ul{
                padding-left: 0;
                margin: 0;
                list-style: none;
                position: absolute;
                z-index: 9;
                top: 0;
                opacity: 0;
                transition: all 0.3s ease;
                background-color: #37f;
                min-width: 160px;
                left: 100%;
                visibility: hidden;
                > li {
                  height: 50px;
                  position: relative;
                  &.divider {
                    margin: 5px 15px;
                    height: 1px;
                    background: #ffffff ;
                    content:none;
                    font-size: 0;
                  }
                  &.divider:hover {
                    background-color: #ffffff;
                  }
                  &:hover {
                    background-color: rgba(26, 29, 36, 0.5);
                  }
                  &:hover > ul {
                    visibility: visible;
                    opacity: 1;
                  }
                  & > a {
                    display: block;
                    height: 100%;
                    line-height: 50px;
                    padding: 0 15px;
                    color: #e0e0e0;
                    &.active{
                      color: #ffffff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .user-info-wrap {
      height: 64px;
      float: right;
      margin-right: 30px;
      display: flex;
      align-items: center;
      .company-container {
        margin-right: 15px;
        .ant-select-selection {
          min-width: 260px;
        }
      }
      .btn-container {
        position: absolute;
        right: 20px;
        line-height: 1;
        .user-info-con {
          display: inline-block;
          padding: 8px 12px;
          line-height: 1;
          cursor: pointer;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(255, 255, 255, 0.08);
          color: #ffffff;
          &:hover {
            border-color: #489eea;
          }
          .name-info {
            margin: 0 5px;
          }
        }
        .option-btn {
          position: absolute;
          width: 100%;
          top: 33px;
          left: 0;
          background: #fff;
          z-index: 100;
          border-radius: 5px;
          overflow: hidden;
          box-shadow: 0 0 3px #eee;
          cursor: pointer;
          .option-text {
            padding: 10px 20px;
            &:hover {
              background: rgba(191, 217, 241, 0.5);
            }
          }
        }
      }
      .ant-select-selection {
        border-radius: 2px;
        border-color: rgba(255, 255, 255, 0.08);
        background: rgba(255, 255, 255, 0.08);
        color: #ffffff;
      }
      .user-info-msg {
        position: absolute;
        left: 10px;
        line-height: 1;
        display: flex;
        align-items: center;
        top: 24px;
        .user-info-name {
          margin-left: 5px;
          display: inline-block;
          max-width: 60px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .ant-layout-sider {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .router-content-wrap {
    overflow-y: auto;
    .router-content {
      margin: 0 20px;
      min-height: 300px;
    }
  }
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #1890ff;
    }
  }
  .ant-layout{
    .ant-layout-has-sider{
      height: 100%;
      .ant-menu-inline,
      .ant-menu-vertical,
      .ant-menu-vertical-left {
        border-right:none;
      }
    }
  }
  .logo {
    &-box {
      min-width: 170px;
      display: flex;
      align-items: center;
      margin-right: rw(140);
    }
  
    &-divider {
      width: 1px;
      height: 16px;
      background-color: rgb(51, 51, 51);
      margin: 0 12px;
    }
  
    &-platform {
      font-size: 18px;
      white-space: nowrap;
    }
  }
  .header-home {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: transparent !important;
  }
  
  .header-sticky {
    background: #272727 !important;
  }
}
